<template>
  <b-row>
    <b-col cols="12">
      <div v-if="loading">
        <b-button class="mb-3" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
        <div class="text-center">
          <b-spinner variant="primary"></b-spinner>
          <p class="mt-2 h6">Loading...</p>
        </div>
      </div>
      <div v-if="!loading && values">
        <div class="d-flex flex-row mb-3">
          <b-button class="align-self-center mr-1" :to="{name: 'reports'}" variant="primary"><fa-icon class="mr-1" icon="arrow-left" /> Reports</b-button>
          <b-button class="align-self-center mr-1" :href="csvLink" variant="light"><fa-icon class="mr-1" icon="download" /> CSV</b-button>
          <div class="flex-fill"></div>
          <div class="justify-content-end d-flex flex-row flex-fill" style="max-width: 700px;">
            <b-checkbox class="align-self-center w-25" v-model="showSettled">Include Settled</b-checkbox>
            <user-search class="w-75" :show-email="false" :show-delete="true" type="owner" v-model="user" />
          </div>
        </div>
        <b-table bordered responsive striped head-variant="dark" :fields="fields" :items="values">
          <template #cell(name)="{ item }">
            <span v-if="item.client_id">{{ item.name }}</span><strong v-if="!item.client_id">TOTAL</strong>
          </template>
          <template #cell(issued_at)="{ item }">
            {{ item.issued_at ? moment(item.issued_at).format('MMMM YYYY') : '' }}
          </template>
          <template #cell(outstanding_inflow)="{ item }">
            {{ item.outstanding_inflow ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.outstanding_inflow) : '£0.00' }}
          </template>
          <template #cell(received_inflow)="{ item }">
            {{ item.received_inflow ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.received_inflow) : '£0.00' }}
          </template>
          <template #cell(amount)="{ item }">
            {{ item.amount ? new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(item.amount) : '£0.00' }}
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment';
import UserSearch from '../../components/UserSearch';
export default {
  components: { UserSearch },
  data() {
    return {
      user: null,
      showSettled: false,
      loading: false,
      values: {},
      fields: [
        { key: 'name', text: 'Owner' },
        { key: 'issued_at', text: 'Issued At' },
        { key: 'outstanding_inflow', text: 'Outstanding Inflow' },
        { key: 'received_inflow', text: 'Received Inflow' },
        { key: 'amount', text: 'Amount' },
      ],
    };
  },
  async mounted() {
    await this.load();
  },
  computed: {
    csvLink() {
      return `/api/reports/client-account.csv?settled=${this.showSettled}${this.user ? '&client=' + this.user : ''}`;
    }
  },
  methods: {
    moment,
    async load() {
      this.loading = true;
      const response = await window.apiFetch(`/reports/client-account?settled=${this.showSettled}${this.user ? '&client=' + this.user : ''}`);
      this.values = await response.json();

      this.loading = false;
    }
  },
  watch: {
    showSettled() {
      return this.load();
    },
    user() {
      return this.load();
    }
  }
}
</script>
